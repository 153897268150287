import * as Sentry from "@sentry/nuxt";

const config = useRuntimeConfig().public;

Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: `client-${config.MODE}`,
  enabled: config.MODE !== "development",
  tracesSampleRate: 1.0,
});
